<template>
  <div>
    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >
      <!-- User Info: Input Fields -->
      <b-form>
        <b-row>
          <!-- Field: Banco -->
          <b-col
            cols="12"
            md="6"
          >
            <validation-provider
              #default="validationContext"
              name="banco"
              rules="required"
              immediate
            >
              <b-form-group
                label="Banco"
                label-for="dentist-bank-name-id"
                :state="getValidationState(validationContext) ? null : false"
              >
                <v-select
                  v-model="dentistData.bank_accounts.bank_id"
                  input-id="dentist-bank-name-id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  clearable
                  :reduce="val => val.value"
                  :options="banks"
                  placeholder="Selecione"
                >
                  <template #no-options="{ }">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Agência -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="agência"
              rules="required"
              immediate
            >
              <b-form-group
                label="Agência"
                label-for="dentist-bank-agency"
              >
                <b-form-input
                  id="dentist-bank-agency"
                  v-model="dentistData.bank_accounts.branch_code"
                  :state="getValidationState(validationContext) ? null : false"
                  type="number"
                  trim
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: DV Agência -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="DV Agência"
              label-for="dentist-dv-bank-agency"
            >
              <b-form-input
                id="dentist-dv-bank-agency"
                v-model="dentistData.bank_accounts.branch_dv"
                type="number"
                trim
              />
            </b-form-group>
          </b-col>

          <!-- Field: Conta Corrente -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="validationContext"
              name="conta corrente"
              rules="required"
              immediate
            >
              <b-form-group
                label="Conta Corrente"
                label-for="dentist-bank-account-number"
              >
                <b-form-input
                  id="dentist-bank-account-number"
                  v-model="dentistData.bank_accounts.code"
                  :state="getValidationState(validationContext) ? null : false"
                  type="number"
                  trim
                />
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: DV Conta Corrent -->
          <b-col
            cols="12"
            md="2"
          >
            <b-form-group
              label="DV Conta Corrente"
              label-for="dentist-bank-account-dv-number"
            >
              <b-form-input
                id="dentist-bank-account-dv-number"
                v-model="dentistData.bank_accounts.dv"
                type="number"
                trim
              />
            </b-form-group>
          </b-col>

          <!-- Field: Chave PIX -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Chave PIX"
              label-for="dentist-bank-pix-key"
            >
              <b-form-input
                id="dentist-bank-pix-key"
                v-model="dentistData.bank_accounts.pix"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="9"
          />

          <b-col
            cols="12"
            md="3"
            class="mt-2"
          >
            <b-button
              variant="primary"
              block
              :disabled="invalid"
              @click="save(dentistData)"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BButton, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { computed } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import store from '@/store'
import useTabBankInformation from './useTabBankInformation'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BFormInvalidFeedback,

    vSelect,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dentistData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    localize('pt_BR') // vee-validate messages

    const {
      save,
      resetBankData,
    } = useTabBankInformation(emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetBankData)

    store.dispatch('banks/fetch')
    const banks = computed(() => store.getters['banks/getToSelect'])

    return {
      banks,
      save,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
