import { ref, watch } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default function useManageSpecialitiesModal() {
  const toast = useToast()

  const refTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'speciality',
      label: 'Nome',
      tdClass: 'labelTdClass',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'ações',
      tdClass: 'actionsTdClass',
      sortable: false,
    },
  ]
  const totalData = ref(0)
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)

  const refetchData = () => {
    refTable.value.refresh()
  }

  watch(searchQuery, () => {
    refetchData()
  })

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const removeSpeciality = async data => {
    try {
      await store.dispatch('store-speciality/delete', data)
      refetchData()
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  const newSpeciality = ref(null)

  const createSpeciality = async speciality => {
    try {
      await store.dispatch('store-speciality/add', { speciality })
      refetchData()
      showToast()
    } catch (e) {
      catchErr(e)
    }
    newSpeciality.value = null
  }

  const editSpeciality = specialityData => {
    store.commit('store-speciality/SET_TO_EDIT', specialityData)
    refetchData()
  }

  const confirmEditSpeciality = async specialityData => {
    try {
      const handleData = JSON.parse(JSON.stringify(specialityData))
      handleData.speciality = handleData.temp
      await store.dispatch('store-speciality/update', handleData)
      refetchData()
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  return {
    tableColumns,
    totalData,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTable,
    refetchData,
    removeSpeciality,
    newSpeciality,
    createSpeciality,
    editSpeciality,
    confirmEditSpeciality,
  }
}
