<template>
  <div>

    <delete-schedules-modal
      :show="deleteSchedulesShowModal"
      @hidden="deleteSchedulesShowModal = false"
    />

    <validation-observer
      #default="{ invalid }"
      ref="refFormObserver"
    >

      <b-form>

        <table class="table table-sm">
          <thead>
            <tr>
              <th scope="col">
                <!-- # -->
              </th>
              <th
                v-for="day in dentistData.office_hour.days"
                :key="day.value"
                scope="col"
              >
                <div
                  class="d-flex justify-content-center"
                >
                  <feather-icon
                    :icon="day.activated ? 'CheckSquareIcon' : 'SquareIcon'"
                    class="text-primary cursor-pointer mr-50"
                    style="margin-top: 1px"
                    size="15"
                    @click="dayActivation(day)"
                  />
                  <span
                    class="ml-50"
                  >
                    {{ day.label }}
                  </span>
                </div>
              </th>

            </tr>
          </thead>

          <tbody scope="row">
            <tr>
              <th scope="row">
                <span
                  class="font-weight-normal"
                >
                  Horário Inicial
                </span>
              </th>

              <td
                v-for="day in dentistData.office_hour.days"
                :key="`${day.value}-start`"
              >
                <div v-if="!day.activated">
                  <b-input-group
                    class="my-1"
                  >
                    <b-form-input
                      :id="`${day.value}-start-disabled`"
                      :key="`${day.value}-start-disabled`"
                      v-model="day.start"
                      type="time"
                      :disabled="!day.activated"
                      trim
                    />
                  </b-input-group>
                </div>

                <div v-else>
                  <validation-provider
                    #default="validationContext"
                    :vid="`${day.value}-start`"
                    name="Horário"
                    rules="required"
                    immediate
                  >
                    <b-input-group
                      class="my-1"
                    >
                      <b-form-input
                        :id="`${day.value}-start`"
                        :key="`${day.value}-start`"
                        v-model="day.start"
                        :state="getValidationState(validationContext) ? null : !day.activated ? null : false"
                        type="time"
                        trim
                      />
                    </b-input-group>
                  </validation-provider>
                </div>
              </td>

            </tr>

            <tr>
              <th scope="row">
                <span
                  class="font-weight-normal"
                >
                  Horário Final
                </span>
              </th>

              <td
                v-for="day in dentistData.office_hour.days"
                :key="`${day.value}-end`"
              >
                <div v-if="!day.activated">
                  <b-input-group
                    class="my-1"
                  >
                    <b-form-input
                      :id="`${day.value}-end-disabled`"
                      :key="`${day.value}-end-disabled`"
                      v-model="day.end"
                      type="time"
                      :disabled="!day.activated"
                      trim
                    />
                  </b-input-group>
                </div>

                <div v-else>
                  <validation-provider
                    #default="validationContext"
                    :vid="`${day.value}-end`"
                    name="Horário"
                    rules="required"
                    immediate
                  >
                    <b-input-group
                      class="my-1"
                    >
                      <b-form-input
                        :id="`${day.value}-end`"
                        :key="`${day.value}-end`"
                        v-model="day.end"
                        :state="getValidationState(validationContext) ? null : !day.activated ? null : false"
                        type="time"
                        trim
                      />
                    </b-input-group>
                  </validation-provider>
                </div>
              </td>

            </tr>

          </tbody>
        </table>

        <hr>

        <b-row>
          <b-col
            cols="12"
            md="2"
            class="mt-2"
          >
            <b-form-group
              label="Horário de Almoço"
              label-for="switch-lunch-break"
              class="mt-0 d-flex justify-content-center"
            >
              <b-form-checkbox
                id="switch-lunch-break"
                v-model="dentistData.office_hour.lunchBreakActivation"
                class="ml-1 d-flex justify-content-center"
                style="top: -2px"
                switch
                @change="clearLunchBreakOnInactive(dentistData)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckCircleIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XCircleIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="2"
          >
            <div v-if="dentistData.office_hour.lunchBreakActivation">
              <validation-provider
                #default="validationContext"
                name="Horário"
                rules="required"
                immediate
              >
                <b-input-group
                  class="my-1"
                >
                  <b-form-input
                    v-model="dentistData.office_hour.lunchBreakStart"
                    :state="getValidationState(validationContext) ? null : false"
                    type="time"
                    trim
                    :disabled="!dentistData.office_hour.lunchBreakActivation"
                  />
                </b-input-group>
              </validation-provider>
            </div>

            <div v-else>
              <b-input-group
                class="my-1"
              >
                <b-form-input
                  v-model="dentistData.office_hour.lunchBreakStart"
                  type="time"
                  trim
                  :disabled="!dentistData.office_hour.lunchBreakActivation"
                />
              </b-input-group>
            </div>
          </b-col>

          <b-col
            cols="12"
            md="2"
          >
            <div v-if="dentistData.office_hour.lunchBreakActivation">
              <validation-provider
                #default="validationContext"
                name="Horário"
                rules="required"
                immediate
              >
                <b-input-group
                  class="my-1"
                >
                  <b-form-input
                    v-model="dentistData.office_hour.lunchBreakEnd"
                    :state="getValidationState(validationContext) ? null : false"
                    type="time"
                    trim
                    :disabled="!dentistData.office_hour.lunchBreakActivation"
                  />
                </b-input-group>
              </validation-provider>
            </div>

            <div v-else>
              <b-input-group
                class="my-1"
              >
                <b-form-input
                  v-model="dentistData.office_hour.lunchBreakEnd"
                  type="time"
                  trim
                  :disabled="!dentistData.office_hour.lunchBreakActivation"
                />
              </b-input-group>
            </div>

          </b-col>

          <b-col
            cols="12"
            md="3"
            class="mt-2 d-flex justify-content-center"
          >
            <b-form-group
              label="Impedir Agendamento"
              label-for="prevent-scheduling"
              class="mt-0 d-flex justify-content-center"
            >
              <b-form-checkbox
                id="prevent-scheduling"
                v-model="dentistData.office_hour.stopScheduling"
                class="ml-1 d-flex justify-content-center"
                style="top: -2px"
                switch
                @change="changeWarnOnStopClick(dentistData)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckCircleIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XCircleIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
            class="mt-2 d-flex justify-content-center"
          >
            <b-form-group
              label="Somente Avisar Indisponibilidade"
              label-for="warn-scheduling-inconsistency"
              class="mt-0 d-flex justify-content-center"
            >
              <b-form-checkbox
                id="warn-scheduling-inconsistency"
                v-model="dentistData.office_hour.warnScheduleInconsistensy"
                class="ml-1 d-flex justify-content-center"
                style="top: -2px"
                switch
                @change="changeStopOnWarnClick(dentistData)"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckCircleIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XCircleIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>
          </b-col>
        </b-row>

        <hr>

        <b-row>

          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
              #default="validationContext"
              name="Duração do Atendimento"
              rules="required|min_value:5"
              immediate
            >

              <b-form-group
                label="Duração do Atendimento (min.)"
                label-for="appointment-duration"
              >
                <b-form-input
                  id="appointment-duration"
                  v-model="dentistData.office_hour.appointmentDuration"
                  :state="getValidationState(validationContext) ? null : false"
                  type="number"
                  trim
                />
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Data Inicial"
              label-for="schedule-period-start"
            >
              <b-form-input
                id="schedule-period-start"
                v-model="dentistData.office_hour.schedulePeriodStart"
                type="date"
                trim
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="Data Final"
              label-for="schedule-period-end"
            >
              <b-form-input
                id="schedule-period-end"
                v-model="dentistData.office_hour.schedulePeriodEnd"
                type="date"
                trim
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="2"
            class="mt-2"
          >
            <b-button
              variant="primary"
              block
              :disabled="invalid"
              @click="launchPayload(dentistData)"
            >
              Salvar
            </b-button>
          </b-col>

          <b-col
            cols="12"
            md="1"
          >
            <b-button
              variant="danger"
              block
              class="mt-2"
              @click="deleteSchedulesModal(dentistData)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </b-col>

        </b-row>

      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormInput, BInputGroup, BFormGroup, BFormCheckbox, BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import useTabSchedules from './useTabSchedules'
import DeleteSchedulesModal from '../DeleteSchedulesModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BFormCheckbox,
    BButton,

    DeleteSchedulesModal,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    dentistData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const {
      stopScheduling,
      warnScheduleInconsistensy,
      changeWarnOnStopClick,
      changeStopOnWarnClick,
      clearLunchBreakOnInactive,
      dayActivation,
      deleteSchedulesShowModal,
      deleteSchedulesModal,

      days,
      schedulesData,
      resetSchedulesData,
      launchPayload,
    } = useTabSchedules(emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetSchedulesData)

    return {
      stopScheduling,
      warnScheduleInconsistensy,
      changeWarnOnStopClick,
      changeStopOnWarnClick,
      clearLunchBreakOnInactive,
      dayActivation,
      deleteSchedulesShowModal,
      deleteSchedulesModal,

      days,
      schedulesData,
      resetSchedulesData,
      launchPayload,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
