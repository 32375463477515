import { ref } from '@vue/composition-api'

export default function useDentalPlan() {
  // <==================== Modal Data ====================>

  // <==================== Payload Data ====================>
  const blankDeleteScheduleForm = {
    period_start: null,
    period_end: null,
  }

  const deleteScheduleForm = ref(JSON.parse(JSON.stringify(blankDeleteScheduleForm)))

  const resetDeleteScheduleForm = () => {
    deleteScheduleForm.value = JSON.parse(JSON.stringify(blankDeleteScheduleForm))
  }

  // <==================== Launch Payload ====================>
  const launchPayload = () => {
    // console.log(deleteScheduleForm.value)
  }

  return {
    deleteScheduleForm,
    resetDeleteScheduleForm,
    launchPayload,
  }
}
