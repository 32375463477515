<template>

  <div>
    <dentist-add-new
      :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
      :dentist="dentist"
      @refreshData="refetchData"
      @refreshUserData="refreshDentistData"
      @reset="reset"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibir</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="8"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block"
                placeholder="Buscar..."
                debounce="500"
              />

            </div>
          </b-col>

          <b-col
            cols="12"
            md="2"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                variant="primary"
                block
                @click="reset(); isAddNewUserSidebarActive = true"
              >
                Cadastrar Dentista
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-overlay
        :show="showOverlay"
        rounded="sm"
        no-fade
      >
        <b-table
          ref="refTable"
          class="position-relative tableMinHeght"
          :items="fetch"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="Nenhum resultado encontrado"
          :sort-desc.sync="isSortDirDesc"
          no-sort-reset
        >
          <!-- Column: Actions -->
          <template #cell(actions)="data">
            <b-dropdown
              variant="link"
              no-caret
              :right="$store.state.appConfig.isRTL"
            >

              <template #button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
                />
              </template>

              <b-dropdown-item @click="editDentist(data.item)">
                <feather-icon icon="EditIcon" />
                <span class="align-middle ml-50">Editar</span>
              </b-dropdown-item>

              <b-dropdown-item
                v-if="!data.item.deleted_at"
                @click="handleDelete(data.item)"
              >
                <feather-icon icon="UserXIcon" />
                <span
                  class="align-middle ml-50"
                >Inativar</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-else
                @click="handleReactivate(data.item)"
              >
                <feather-icon icon="UserCheckIcon" />
                <span
                  class="align-middle ml-50"
                >Reativar</span>
              </b-dropdown-item>
            </b-dropdown>
          </template>

        </b-table>
      </b-overlay>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} de {{ dataMeta.of }} entradas</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalData"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BTable, BDropdown, BDropdownItem, BPagination, BButton, BOverlay,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

// eslint-disable-next-line import/no-cycle
import useDentist from '../useDentist'

import DentistAddNew from './DentistAddNew.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BButton,
    BOverlay,

    DentistAddNew,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,
  },
  setup(props, { root }) {
    const isAddNewUserSidebarActive = ref(false)
    const {
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTable,
      dentist,
      blankDentistData,
      showOverlay,
      refetchData,
      fetch,
      deleteData,
      reactiveData,
    } = useDentist()

    const handleDelete = data => {
      root.$bvModal
        .msgBoxConfirm(`Deseja Inativar o Usuário ${data.name}?`, {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) deleteData(data)
        })
    }

    const handleReactivate = data => {
      root.$bvModal
        .msgBoxConfirm(`Deseja Reativar o Usuário ${data.name}?`, {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) reactiveData(data)
        })
    }

    const editDentist = dentistData => {
      store.commit('app-user/SET', dentistData)
      dentist.value = JSON.parse(JSON.stringify(store.getters['app-user/user']))
      isAddNewUserSidebarActive.value = true
    }

    const refreshDentistData = () => {
      dentist.value = JSON.parse(JSON.stringify(store.getters['app-user/user']))
    }

    const reset = () => {
      dentist.value = JSON.parse(JSON.stringify(blankDentistData))
    }

    return {
      tableColumns,
      perPage,
      currentPage,
      totalData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTable,
      dentist,
      isAddNewUserSidebarActive,
      showOverlay,
      editDentist,
      refreshDentistData,
      fetch,
      handleDelete,
      handleReactivate,
      refetchData,
      deleteData,
      reactiveData,
      reset,
    }
  },
}
</script>

<style lang="scss" scoped>
  @import '@core/scss/vue/libs/vue-select.scss';
  .codeTdClass {
    width: 150px;
  }
  .statusTdClass {
    width: 120px;
  }
  .actionsTdClass {
    width: 120px;
  }
    // resolve a cor do overlay
.dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }
}

</style>
