import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ref } from '@vue/composition-api'

import store from '@/store'

export default function useTabSchedules(emit) {
  const toast = useToast()

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  // <==================== Launch Payload ====================>

  const launchPayload = async dentistData => {
    try {
      if (dentistData.id) await store.dispatch('app-user/update', dentistData)
      else {
        await store.dispatch('app-user/add', dentistData)
        emit('refreshUserData')
      }
      emit('refreshData')
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  // <==================== Schedules Tab Functions ====================>

  const changeWarnOnStopClick = dentistData => {
    // eslint-disable-next-line no-param-reassign
    dentistData.office_hour.warnScheduleInconsistensy = !dentistData.office_hour.warnScheduleInconsistensy
  }

  const changeStopOnWarnClick = dentistData => {
    // eslint-disable-next-line no-param-reassign
    dentistData.office_hour.stopScheduling = !dentistData.office_hour.stopScheduling
  }

  const clearLunchBreakOnInactive = dentistData => {
    const data = dentistData
    if (data.office_hour.lunchBreakActivation === false) {
      data.office_hour.lunchBreakStart = null
      data.office_hour.lunchBreakEnd = null
    }
  }

  const dayActivation = day => {
    const data = day
    data.start = null
    data.end = null
    data.activated = !data.activated
  }

  // <==================== Schedules Modal ====================>

  const deleteSchedulesShowModal = ref(false)

  const deleteSchedulesModal = dentistData => {
    // eslint-disable-next-line no-console
    console.log(dentistData)
    deleteSchedulesShowModal.value = true
  }

  return {
    deleteSchedulesShowModal,
    changeWarnOnStopClick,
    changeStopOnWarnClick,
    clearLunchBreakOnInactive,
    dayActivation,
    deleteSchedulesModal,
    launchPayload,
  }
}
