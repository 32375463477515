// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { computed, ref } from '@vue/composition-api'

import store from '@/store'

export default function useTabMain(emit, dentistData) {
  const genderOptions = [
    { label: 'Masculino', value: 'MALE' },
    { label: 'Feminino', value: 'FEMALE' },
    { label: 'Indefinido', value: 'UNDEFINED' },
  ]

  const maritalStatusOptions = [
    { label: 'Casado(a)', value: 'married' },
    { label: 'Convivente', value: 'cohabitant' },
    { label: 'Solteiro(a)', value: 'single' },
    { label: 'Viúvo(a)', value: 'widdow' },
    { label: 'Divorciado(a)', value: 'divorced' },
  ]

  const roleOptions = [
    { label: 'Dentista', value: 'DENTIST' },
    { label: 'Administrador', value: 'ADMIN' },
  ]

  // Use toast
  const toast = useToast()

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const webcam = ref(null)
  const loadCamera = () => {
    webcam.value.loadCamera()
  }
  const passwordFieldType = ref('password')
  const togglePasswordVisibility = () => {
    passwordFieldType.value = passwordFieldType.value === 'password' ? 'text' : 'password'
  }
  const userData = computed(() => store.getters['loggedUser/getLoggedUser'])

  const passwordToggleIcon = computed(() => (passwordFieldType.value === 'password' ? 'EyeIcon' : 'EyeOffIcon'))
  const mouseOver = ref(null)
  const showOverlay = ref(false)
  // eslint-disable-next-line global-require
  const noUserPhoto = require('@/assets/images/avatars/no-picture.png')

  const onValueChanged = async value => {
    // eslint-disable-next-line no-param-reassign
    dentistData.value.photo = value.base_img
    if (dentistData.value.id) {
      try {
        showOverlay.value = true
        const resp = await store.dispatch('app-user/changePhoto', dentistData.value)
        if (userData.value.id === resp.data.id) {
          const tmp = JSON.parse(JSON.stringify(userData.value))
          tmp.photo = resp.data.photo
          store.commit('loggedUser/SET_LOGGED_USER', tmp)
          localStorage.setItem('userData', JSON.stringify(tmp))
        }
        emit('refreshData')
        showToast()
        showOverlay.value = false
      } catch (e) {
        showOverlay.value = false
        catchErr(e)
      }
    }
  }

  const save = async dentist => {
    try {
      let resp = null
      if (dentist.id) resp = await store.dispatch('app-user/update', dentist)
      else {
        resp = await store.dispatch('app-user/add', dentist)
        emit('refreshUserData')
      }

      if (userData.value.id === resp.data.id) {
        store.commit('loggedUser/SET_LOGGED_USER', resp.data)
        localStorage.setItem('userData', JSON.stringify(resp.data))
      }

      emit('refreshData')
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  return {
    genderOptions,
    maritalStatusOptions,
    roleOptions,
    webcam,
    passwordToggleIcon,
    mouseOver,
    showOverlay,
    noUserPhoto,
    passwordFieldType,
    togglePasswordVisibility,
    onValueChanged,
    loadCamera,
    save,
  }
}
