import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default function useTabAddress(emit) {
  const publicPlaceOptions = [
    { label: 'Rua', value: 'Rua' },
    { label: 'Avenida', value: 'Avenida' },
    { label: 'Rodovia', value: 'Rodovia' },
    { label: 'Estrada', value: 'Estrada' },
    { label: 'Lote', value: 'Lote' },
    { label: 'Servidão', value: 'Servidão' },
    { label: 'Quadra', value: 'Quadra' },
    { label: 'Travessa', value: 'Travessa' },
    { label: 'Linha', value: 'Linha' },
  ]

  // Use toast
  const toast = useToast()

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const save = async dentistData => {
    try {
      if (dentistData.id) await store.dispatch('app-user/update', dentistData)
      else await store.dispatch('app-user/add', dentistData)
      emit('refreshData')
      showToast()
    } catch (e) {
      catchErr(e)
    }
  }

  return {
    save,
    publicPlaceOptions,
  }
}
