import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default function useDentalPlan(dentistData) {
  // Use toast
  const toast = useToast()

  const refTable = ref(null)
  const fileUrl = ref(null)
  const mimeType = ref(null)
  const modalSeeFileShow = ref(false)
  const showOverlay = ref(false)

  // Table Handlers
  const tableColumns = [
    {
      key: 'name',
      label: 'Nome',
      tdClass: 'nameTdClass',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'ações',
      tdClass: 'actionsTdClass',
      sortable: false,
    },
  ]
  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)

  const dataMeta = computed(() => {
    const localItemsCount = refTable.value ? refTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const refetchData = () => {
    refTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const seeFile = async data => {
    try {
      fileUrl.value = await store.dispatch('store-file/getUrl', data.id)
      if (data.mime.substring(0, 6) === 'image/' || data.mime === 'application/pdf') {
        mimeType.value = data.mime
        modalSeeFileShow.value = true
      } else {
        const anchor = document.createElement('a')
        anchor.href = fileUrl.value
        anchor.download = fileUrl.value
        document.body.appendChild(anchor)
        anchor.click()
      }
    } catch (e) {
      // catchErr(e)
    }
  }

  const fetch = (ctx, callback) => {
    showOverlay.value = true
    store
      .dispatch('store-file/fetch', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
        category: 'documents',
        userId: dentistData.id,
      })
      .then(resp => {
        showOverlay.value = false
        const { data, total } = resp.data

        callback(data)
        totalData.value = total
      })
      .catch(e => {
        showOverlay.value = false
        catchErr(e)
      })
  }

  const deleteDocumentData = async data => {
    try {
      await store.dispatch('store-file/delete', data)
      showToast('success', 'CheckIcon', 'Sucesso', 'Dados Excluídos com Sucesso.')
      refetchData()
    } catch (e) {
      catchErr(e)
    }
  }

  const uploadDocumentShowModal = ref(false)

  const uploadDocumentModal = () => {
    uploadDocumentShowModal.value = true
  }

  const docTempName = ref(null)
  const docTempType = ref({})

  const activateEditMode = data => {
    docTempName.value = data.item.name
    docTempType.value = data.item.type
    store.commit('store-file/SET_TO_EDIT', data)
    refetchData()
  }

  const confirmEditDocument = async data => {
    try {
      // eslint-disable-next-line no-param-reassign
      data.item.name = docTempName.value
      // eslint-disable-next-line no-param-reassign
      data.item.type = docTempType.value
      await store.dispatch('store-file/update', data.item)
      showToast('success', 'CheckIcon', 'Sucesso', 'Dados Salvos com Sucesso.')
      store.commit('store-file/SET_TO_NO_EDIT', data)
      refetchData()
    } catch (e) {
      catchErr(e)
    }
  }

  return {
    fetch,
    tableColumns,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTable,
    fileUrl,
    mimeType,
    modalSeeFileShow,

    seeFile,
    deleteDocumentData,
    uploadDocumentModal,
    uploadDocumentShowModal,
    confirmEditDocument,
    docTempName,
    docTempType,
    activateEditMode,

    refetchData,
  }
}
