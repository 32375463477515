<template>
  <div>
    <b-sidebar
      id="add-new-user-sidebar"
      :visible="isAddNewUserSidebarActive"
      :sidebar-class="{
        'expanded-menu': !isVerticalMenuCollapsed,
        'retracted-menu': isVerticalMenuCollapsed,
        'no-xl' : currentBreakPoint && currentBreakPoint !== 'xl'
      }"
      bg-variant="white"
      no-header
      z-index="10"
      right
      @hidden="$emit('reset'); idxTab = 0"
      @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
    >

      <template #default="{ hide }">
        <b-overlay
          :show="showOverlay"
          rounded="sm"
          no-fade
        >
          <!-- Header -->
          <div
            style="padding-top: 85px"
            class="d-flex content-sidebar-header"
          >
            <feather-icon
              class="cursor-pointer m-1"
              icon="ArrowLeftIcon"
              size="25"
              @click="hide"
            />
          </div>

          <div class="d-flex justify-content-between align-items-center px-2 py-1">
            <h5 class="mb-0">
              Cadastro de Dentista
            </h5>
          </div>

          <div>
            <b-form
              class="p-2"
              @submit.prevent="onSubmit"
              @reset.prevent="resetForm"
            >

              <b-tabs
                v-model="idxTab"
                small
              >
                <!-- Tab: Dados Gerais -->
                <b-tab
                  :active.sync="activeTabMain"
                  @click="refreshUserData('activeTabMain')"
                >
                  <template #title>
                    <feather-icon
                      icon="InfoIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Dados Gerais</span>
                  </template>
                  <tab-main
                    :dentist-data="dentist"
                    @refreshData="$emit('refreshData')"
                    @refreshUserData="refreshUserData"
                  />
                </b-tab>

                <!-- Tab: Endereço -->
                <b-tab
                  v-if="dentist.id"
                  :active.sync="activeTabAddress"
                  @click="refreshUserData('activeTabAddress')"
                >
                  <template #title>
                    <feather-icon
                      icon="MapPinIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Endereço</span>
                  </template>
                  <tab-address
                    :dentist-data="dentist"
                    @refreshData="$emit('refreshData')"
                  />
                </b-tab>

                <!-- Tab: Dados Bancários -->
                <b-tab
                  v-if="dentist.id"
                  :active.sync="activeTabBankInfo"
                  @click="refreshUserData('activeTabBankInfo')"
                >
                  <template #title>
                    <feather-icon
                      icon="DollarSignIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Dados Bancários</span>
                  </template>
                  <tab-bank-information
                    :dentist-data="dentist"
                    @refreshData="$emit('refreshData')"
                  />
                </b-tab>

                <!-- Tab: Atividade -->
                <b-tab
                  v-if="dentist.id"
                  :active.sync="activeTabActivity"
                  @click="refreshUserData('activeTabActivity')"
                >
                  <template #title>
                    <feather-icon
                      icon="BriefcaseIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Atividade</span>
                  </template>
                  <tab-activity
                    :dentist-data="dentist"
                    @refreshData="$emit('refreshData')"
                  />
                </b-tab>

                <!-- Tab: Horários -->
                <b-tab
                  v-if="dentist.id"
                  :active.sync="activeTabSchedules"
                  @click="refreshUserData('activeTabSchedules')"
                >
                  <template #title>
                    <feather-icon
                      icon="ClockIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Horários</span>
                  </template>
                  <tab-schedules
                    :dentist-data="dentist"
                    @refreshData="$emit('refreshData')"
                  />
                </b-tab>

                <!-- Tab: Documentos -->
                <b-tab
                  v-if="dentist.id"
                  :active.sync="activeTabDocuments"
                  @click="refreshUserData('activeTabDocuments')"
                >
                  <template #title>
                    <feather-icon
                      icon="FileIcon"
                      size="16"
                      class="mr-0 mr-sm-50"
                    />
                    <span class="d-none d-sm-inline">Documentos</span>
                  </template>
                  <tab-documents
                    :dentist-data="dentist"
                    @refreshData="$emit('refreshData')"
                  />
                </b-tab>
              </b-tabs>
            </b-form>
          </div>
        </b-overlay>
      </template>

    </b-sidebar>
  </div>
</template>

<script>
// import { nextTick } from 'vue'
import {
  BOverlay, BSidebar, BForm, BTab, BTabs,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import {
  required, email, cpfCnpj,
} from '@validations'
import Ripple from 'vue-ripple-directive'
import { mask } from 'vue-the-mask'
import TabMain from '@/views/apps/dentists/list/tabs/TabMain.vue'
import TabAddress from '@/views/apps/dentists/list/tabs/TabAddress.vue'
import TabBankInformation from '@/views/apps/dentists/list/tabs/TabBankInformation.vue'
import TabActivity from '@/views/apps/dentists/list/tabs/TabActivity.vue'
import TabDocuments from '@/views/apps/dentists/list/tabs/TabDocuments.vue'
import TabSchedules from '@/views/apps/dentists/list/tabs/TabSchedules.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BOverlay,
    BTab,
    BTabs,

    TabMain,
    TabAddress,
    TabBankInformation,
    TabActivity,
    TabDocuments,
    TabSchedules,

  },
  directives: {
    Ripple,
    mask,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    dentist: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      required,
      email,
      cpfCnpj,
      showOverlay: false,
    }
  },
  computed: {
    isVerticalMenuCollapsed() {
      return this.$store.state.verticalMenu.isVerticalMenuCollapsed
    },
    currentBreakPoint() {
      return this.$store.getters['app/currentBreakPoint']
    },
  },
  setup(props, { emit }) {
    const activeTabMain = ref(true)
    const activeTabAddress = ref(false)
    const activeTabBankInfo = ref(false)
    const activeTabActivity = ref(false)
    const activeTabSchedules = ref(false)
    const activeTabDocuments = ref(false)
    const idxTab = ref(0)

    const refreshUserData = data => {
      if (data === 'activeTabMain' && !activeTabMain.value) emit('refreshUserData')
      else if (data === 'activeTabAddress' && !activeTabAddress.value) emit('refreshUserData')
      else if (data === 'activeTabBankInfo' && !activeTabBankInfo.value) emit('refreshUserData')
      else if (data === 'activeTabActivity' && !activeTabActivity.value) emit('refreshUserData')
      else if (data === 'activeTabSchedules' && !activeTabSchedules.value) emit('refreshUserData')
      else if (data === 'activeTabDocuments' && !activeTabDocuments.value) emit('refreshUserData')
      else emit('refreshUserData', data)
    }

    return {
      activeTabMain,
      activeTabAddress,
      activeTabBankInfo,
      activeTabActivity,
      activeTabSchedules,
      activeTabDocuments,
      idxTab,

      refreshUserData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}

.expanded-menu {
  width: calc(100% - 288px);
  }

.retracted-menu {
  width: calc(100% - 108px);
  }

.no-xl {
  width: calc(100% - 28px);
}

// resolve a cor do overlay
.dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }
}

</style>
