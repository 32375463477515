<template>
  <div>

    <webcam-photo
      ref="webcam"
      @snapShot="onValueChanged"
    />

    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >
      <b-form>
        <b-row>

          <b-col
            cols="12"
            md="12"
          >
            <b-row>

              <!-- Field: Profile Picture -->
              <b-col
                class="text-center"
                cols="12"
                md="4"
              >
                <div
                  class="mt-1 d-flex align-self-center justify-content-center cursor-pointer"
                  @mouseover="mouseOver = true"
                  @mouseleave="mouseOver = false"
                  @click="loadCamera"
                >
                  <b-overlay
                    :show="showOverlay"
                    rounded="sm"
                    no-fade
                  >
                    <b-avatar
                      rounded="lg"
                      variant="light-primary"
                      size="125"
                    >
                      <feather-icon
                        v-if="!dentistData.photo || !dentistData.photo.url"
                        :icon="mouseOver === false ? 'UserIcon' : mouseOver === null ? 'UserIcon' : 'CameraIcon'"
                        :size="mouseOver === false ? '85' : mouseOver === null ? '85' : '60'"
                      />

                      <div
                        v-if="(dentistData.photo && dentistData.photo.url) || dentistData.photo"
                        class="position-relative d-flex justify-content-center align-items-center"
                      >
                        <img
                          class="rounded"
                          style="width: 135px; height: 135px;"
                          :src="(dentistData.photo && dentistData.photo.url) ? dentistData.photo.url : dentistData.photo"
                          :alt="dentistData.name"
                        >
                        <feather-icon
                          v-if="mouseOver === true"
                          icon="CameraIcon"
                          size="60"
                          class="position-absolute"
                        />
                      </div>
                    </b-avatar>
                  </b-overlay>
                </div>
              </b-col>

              <!-- Field: Código -->
              <b-col
                cols="12"
                md="8"
              >
                <b-row>
                  <b-col
                    cols="12"
                    md="3"
                  >
                    <b-form-group
                      label="Código"
                      label-for="dentist-id-code"
                    >
                      <b-form-input
                        id="dentist-id-code"
                        ref="dentist-id-code"
                        v-model="dentistData.code"
                        disabled
                        trim
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: Nome Completo -->
                  <b-col
                    cols="12"
                    md="9"
                  >

                    <validation-provider
                      #default="{ errors }"
                      name="Nome Completo"
                      rules="required"
                      immediate
                    >
                      <b-form-group
                        label="Nome Completo"
                        label-for="dentist-name"
                      >
                        <b-form-input
                          id="dentist-name"
                          v-model="dentistData.name"
                          :state="errors.length > 0 ? false : null"
                          trim
                        />
                        <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Field: Como gostaria de ser chamado? -->
                  <b-col
                    cols="12"
                    md="8"
                  >
                    <b-form-group
                      label="Como gostaria de ser chamado"
                      label-for="dentist-preferred-name"
                    >
                      <b-form-input
                        id="dentist-preferred-name"
                        v-model="dentistData.nickname"
                        trim
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field: Data de Nascimento -->
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <b-form-group
                      label="Data de Nascimento"
                      label-for="birth-date"
                    >
                      <b-form-input
                        id="birth-date"
                        v-model="dentistData.birthday"
                        type="date"
                        trim
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>

          <!-- Field: e-mail -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
              immediate
            >
              <b-form-group
                label="E-mail"
                label-for="dentist-email"
              >
                <b-form-input
                  id="dentist-email"
                  v-model="dentistData.email"
                  :state="errors.length > 0 ? false : null"
                  type="email"
                  trim
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Senha -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group>
              <div
                style="top: -1px; left: 60px"
                class="position-absolute"
              >
                <feather-icon
                  v-if="dentistData.id"
                  id="pass-id"
                  icon="InfoIcon"
                  size="12"
                />
                <b-tooltip
                  target="pass-id"
                  noninteractive
                  :delay="{show: 300, hide: 125}"
                >
                  Deixe em branco para NÃO alterar.
                </b-tooltip>
              </div>
              <label for="dentist-password">Senha</label>
              <validation-provider
                #default="{ errors }"
                name="senha"
                :rules="dentistData.id ? 'min:8' : 'required|min:8'"
                immediate
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="dentist-password"
                    v-model="dentistData.password"
                    :type="passwordFieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false : null"
                    name="dentist-password"
                    placeholder="Senha para login do destista"
                    trim
                  />

                  <b-input-group-append is-text>
                    <feather-icon
                      class="cursor-pointer"
                      :icon="passwordToggleIcon"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- Field: Role -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Função"
              label-for="dentist-role"
            >
              <v-select
                v-model="dentistData.role"
                input-id="dentist-role"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :reduce="val => val.value"
                :options="roleOptions"
                :clearable="false"
                :searchable="false"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Telefone Celular -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="telefone celular"
              rules="required"
              immediate
            >
              <b-form-group
                label="Telefone Celular"
                label-for="dentist-phone-one"
              >
                <b-form-input
                  id="dentist-phone-one"
                  v-model="dentistData.phones[0].phone"
                  v-mask="['(##) ####-#####', '(##) # ####-####']"
                  :state="errors.length > 0 ? false : null"
                  trim
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: Telefone Fixo -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Telefone Fixo (opcional)"
              label-for="dentist-phone-two"
            >
              <b-form-input
                id="dentist-phone-two"
                v-model="dentistData.phones[1].phone"
                v-mask="['(##) ####-#####', '(##) # ####-####']"
                trim
              />
            </b-form-group>
          </b-col>

          <!-- Field: Telefone Opcional -->
          <b-col
            cols="12"
            md="4"
          >
            <b-form-group
              label="Telefone (opcional)"
              label-for="dentist-phone-three"
            >
              <b-form-input
                id="dentist-phone-three"
                v-model="dentistData.phones[2].phone"
                v-mask="['(##) ####-#####', '(##) # ####-####']"
                trim
              />
            </b-form-group>
          </b-col>

          <!-- Field: Gênero -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Sexo"
              label-for="dentist-gender"
            >
              <v-select
                v-model="dentistData.gender"
                input-id="dentist-gender"
                :options="genderOptions"
                :reduce="val => val.value"
                :clearable="false"
                :searchable="false"
                placeholder="Selecione"
              />
            </b-form-group>
          </b-col>

          <!-- Field: Estado Civil -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              label="Estado Civil"
              label-for="dentist-marital-status"
            >
              <v-select
                v-model="dentistData.marital_status"
                :options="maritalStatusOptions"
                :reduce="val => val.value"
                :clearable="false"
                :searchable="false"
                input-id="dentist-marital-status"
                placeholder="Selecione"
              />
            </b-form-group>
          </b-col>

          <!-- Field: RG -->
          <b-col
            cols="12"
            md="3"
          >
            <b-form-group
              label="RG"
              label-for="identification-two"
            >
              <b-form-input
                id="identification-two"
                v-model="dentistData.identification2"
                trim
              />
            </b-form-group>
          </b-col>

          <!-- Field: CPF -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              :rules="$store.getters['app-user/user'].identification1 !== dentistData.identification1 ? `required|cpfCnpj|checkDoubleCpf:${dentistData.id},DENTIST,ADMIN` : 'required|cpfCnpj'"
              name="CPF"
              immediate
            >
              <b-form-group
                label="CPF"
                label-for="identification-one"
              >
                <b-form-input
                  id="identification-one"
                  v-model="dentistData.identification1"
                  v-mask="['###.###.###-##']"
                  :state="errors.length > 0 ? false : null"
                  trim
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: CRO -->
          <b-col
            cols="12"
            md="3"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="CRO"
              immediate
            >
              <b-form-group
                label="CRO"
                label-for="identification-cro"
              >
                <b-form-input
                  id="identification-cro"
                  v-model="dentistData.cro"
                  :state="errors.length > 0 ? false : null"
                  trim
                />
                <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- Field: CRO State -->
          <b-col
            cols="12"
            md="2"
          >
            <validation-provider
              #default="validationContext"
              name="UF-CRO"
              rules="required"
              immediate
            >
              <b-form-group
                label="UF-CRO"
                label-for="identification-cro-state"
                :state="getValidationState(validationContext) ? null : false"
              >
                <v-select
                  v-model="dentistData.cro_uf_id"
                  input-id="identification-cro-state"
                  :options="$store.getters['states/getStates']"
                  :reduce="val => val.value"
                  :clearable="false"
                  :searchable="true"
                />
                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="9"
          />

          <b-col
            cols="12"
            md="3"
            class="mt-2"
          >
            <b-button
              variant="primary"
              :disabled="invalid"
              block
              @click="save(dentistData)"
            >
              Salvar
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BForm, BFormInvalidFeedback, BButton, BInputGroup, BInputGroupAppend,
  BOverlay, BTooltip, BAvatar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { mask } from 'vue-the-mask'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import WebcamPhoto from '@/views/components/webcam-photo/WebcamPhoto.vue'
import store from '@/store'
import { toRefs } from '@vue/composition-api'
import useTabMain from './useTabMain'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormInvalidFeedback,
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    BTooltip,
    BAvatar,

    vSelect,

    WebcamPhoto,

    ValidationProvider,
    ValidationObserver,
  },

  directives: {
    mask,
  },

  props: {
    dentistData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    localize('pt_BR') // vee-validate messages
    store.dispatch('states/fetchStates')
    const { dentistData } = toRefs(props)

    const {
      genderOptions,
      maritalStatusOptions,
      roleOptions,
      webcam,
      passwordToggleIcon,
      passwordFieldType,
      mouseOver,
      showOverlay,
      noUserPhoto,
      togglePasswordVisibility,
      loadCamera,
      onValueChanged,
      save,
    } = useTabMain(emit, dentistData)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    return {
      genderOptions,
      maritalStatusOptions,
      roleOptions,
      mouseOver,
      showOverlay,
      passwordToggleIcon,
      passwordFieldType,
      refFormObserver,
      noUserPhoto,
      webcam,
      save,
      togglePasswordVisibility,
      getValidationState,
      resetForm,
      loadCamera,
      onValueChanged,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
// resolve a cor do overlay
.dark-layout {
  div ::v-deep {
    .b-overlay {
      .bg-light {
        background-color: $theme-dark-body-bg !important;
      }
    }
  }
}
</style>
