import Vue from 'vue'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    speciality: {},
    specialities: [],
  },

  getters: {
    forSelect(state) {
      if (Array.isArray(state.specialities)) {
        const data = []
        // eslint-disable-next-line array-callback-return
        state.specialities.map(e => {
          data.push({
            value: e.id,
            label: e.speciality,
            data: e,
          })
        })
        data.sort((a, b) => a.label.localeCompare(b.label)) // Ordena por speciality
        return data
      }
      return []
    },
  },

  mutations: {
    SET(state, data) {
      state.speciality = data
    },

    SET_LIST(state, data) {
      state.specialities = data
    },

    SET_TO_EDIT(state, data) {
      Object.assign(state.specialities[data.index], { editMode: true, temp: data.item.speciality })
    },

    ADD_IN_LIST(state, data) {
      if (Array.isArray(state.specialities)) state.specialities.push(data)
    },

    UPDATE_IN_LIST(state, data) {
      if (Array.isArray(state.specialities)) {
        const idx = state.specialities.findIndex(o => o.id === data.id)
        if (idx >= 0) Vue.set(state.specialities, idx, data)
      }
    },

    DELETE_REGISTER(state, payload) {
      const idx = state.specialities.findIndex(o => o.id === payload)
      if (idx >= 0) {
        state.specialities.splice(idx, 1)
      }
      if (Array.isArray(state.specialities)) {
        const idx2 = state.specialities.findIndex(o => o.id === payload)
        if (idx2 >= 0) {
          state.specialities.splice(idx, 1)
        }
      }
      if (state.speciality && state.speciality.id === payload) {
        state.speciality = {}
      }
    },
  },

  actions: {
    async fetch({ state, commit }, args) {
      try {
        if (!state.specialities.length || args) {
          const resp = await axios.get(`${process.env.VUE_APP_API_URL}/specialities${args ? `?${new URLSearchParams(args).toString()}` : '/'}`)
          commit('SET_LIST', resp.data)

          return resp
        }
        return state.specialities
      } catch (e) {
        return e
      }
    },

    fetchOne({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${process.env.VUE_APP_API_URL}/specialities/${id}`)
          .then(response => {
            commit('SET', response.data)
            commit('UPDATE_IN_LIST', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    add({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${process.env.VUE_APP_API_URL}/speciality`, data)
          .then(response => {
            commit('SET', response.data)
            commit('ADD_IN_LIST', response.data)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    update({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .put(`${process.env.VUE_APP_API_URL}/speciality/${data.id}`, data)
          .then(response => {
            commit('SET', response.data)
            commit('UPDATE_IN_LIST', response.data)
            return resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    async delete({ commit }, data) {
      try {
        const resp = await axios.delete(`${process.env.VUE_APP_API_URL}/speciality/${data.id}`)
        commit('DELETE_REGISTER', data.id)
        return resp
      } catch (e) {
        return e
      }
    },
  },
}
