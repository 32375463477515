<template>
  <div>

    <manage-specialities-modal
      :specialities="specialities"
      :show="manageSpecialitiesShowModal"
      @hidden="manageSpecialitiesShowModal = false"
    />

    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: Especialidade -->
        <b-col
          cols="12"
          md="6"
        >
          <b-row>
            <b-col
              cols="12"
              md="10"
            >
              <b-form-group
                label="Especialidades"
                label-for="dentist-specialities"
              >
                <v-select
                  v-model="dentistData.specialities"
                  input-id="dentist-specialities"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  clearable
                  multiple
                  :options="specialityOptions"
                  :reduce="val => val.value"
                  placeholder="Selecione"
                >
                  <template #no-options="{ }">
                    Nenhum resultado encontrado.
                  </template>
                </v-select>
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
              md="2"
            >
              <label>Nova esp.</label>
              <b-button
                variant="primary"
                block
                @click="manageSpecialities"
              >
                <feather-icon icon="PlusCircleIcon" />
              </b-button>
            </b-col>
          </b-row>
        </b-col>

        <!-- Field: Ativo / Inativo -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Ativo / Inativo"
            label-for="dentist-active"
          >
            <v-select
              v-model="dentistData.status"
              input-id="dentist-active"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="val => val.value"
              :options="[
                { label: 'Ativo', value: 1 },
                { label: 'Inativo', value: 0 },
              ]"
              :clearable="false"
              :searchable="false"
              placeholder="Selecione"
            >
              <template #no-options="{ }">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- Field: Dentista ou Gestor (agendas) -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Acesso a quais agendas?"
            label-for="dentist-main-schedule-control"
          >
            <div
              style="top: -1px; left: 185px"
              class="position-absolute"
            >
              <feather-icon
                id="schedule-info"
                icon="InfoIcon"
                size="12"
              />
              <b-tooltip
                target="schedule-info"
                noninteractive
                :delay="{show: 300, hide: 125}"
              >
                Selecione quais agendas este usuário terá acesso a consulta. Deixe em branco para TODAS.
              </b-tooltip>
            </div>
            <v-select
              v-model="dentistData.schedule_view_users_id"
              input-id="dentist-main-schedule-control"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="dentistOptions"
              :reduce="val => val.value"
              multiple
              :clearable="false"
              :searchable="false"
              placeholder="Selecione"
            >
              <template #no-options="{ }">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <!-- Field: Plano de Contas -->
        <b-col
          cols="12"
          md="6"
        >
          <b-form-group
            label="Plano de Contas"
            label-for="dentist-account-plan"
          >
            <v-select
              id="account-plan-id"
              v-model="dentistData.account_plan_id"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :reduce="val => val.value"
              :clearable="true"
              :options="accountPlans"
              placeholder="Selecione"
            >
              <template #no-options="{}">
                Nenhum resultado encontrado.
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="9"
        />

        <b-col
          cols="12"
          md="3"
          class="mt-2"
        >
          <b-button
            variant="primary"
            block
            @click="save(dentistData)"
          >
            Salvar
          </b-button>
        </b-col>

      </b-row>
    </b-form>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BForm, BTooltip, BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line object-curly-newline
import { computed, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import accountPlanStoreModule from '@/views/apps/finances/account-plans/useStoreAccountPlans'
import specialityStoreModule from '@/views/apps/dentists/list/tabs/specialities/SpecialityStoreModule'

import store from '@/store'
import useTabActivity from './useTabActivity'
import manageSpecialitiesModal from '../ManageSpecialitiesModal.vue'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BTooltip,
    BButton,

    vSelect,
    manageSpecialitiesModal,
  },
  props: {
    dentistData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const {
      manageSpecialitiesShowModal,
      manageSpecialities,
      save,
    } = useTabActivity(emit)

    const USER_APP_STORE_MODULE_ACCOUNT_PLAN = 'store-account-plan'
    const USER_APP_STORE_MODULE_SPECIALITY = 'store-speciality'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_ACCOUNT_PLAN)) store.registerModule(USER_APP_STORE_MODULE_ACCOUNT_PLAN, accountPlanStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_SPECIALITY)) store.registerModule(USER_APP_STORE_MODULE_SPECIALITY, specialityStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_ACCOUNT_PLAN)) store.unregisterModule(USER_APP_STORE_MODULE_ACCOUNT_PLAN)
      if (store.hasModule(USER_APP_STORE_MODULE_SPECIALITY)) store.unregisterModule(USER_APP_STORE_MODULE_SPECIALITY)
    })

    store.dispatch('app-user/fetchAll', { roles: ['ADMIN', 'DENTIST'] })
    const dentistOptions = computed(() => store.getters['app-user/forSelect'])

    store.dispatch('store-account-plan/fetch')
    const accountPlans = computed(() => store.getters['store-account-plan/forSelect']('3 - DESPESAS'))
    store.dispatch('store-speciality/fetch')
    const specialityOptions = computed(() => store.getters['store-speciality/forSelect'])
    const specialities = computed(() => store.state['store-speciality'].specialities)

    return {
      specialities,
      specialityOptions,
      dentistOptions,
      manageSpecialitiesShowModal,
      manageSpecialities,
      save,

      accountPlans,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
