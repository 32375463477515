<template>
  <div>

    <validation-observer
      #default="{invalid}"
      ref="refFormObserver"
    >

      <b-modal
        id="modal-specializations"
        v-model="showLocal"
        centered
        size="lg"
        title="Especializações"
        hide-footer
        @hidden="hidden"
      >
        <b-form>
          <b-row>
            <b-col
              cols="12"
              md="8"
            >
              <validation-provider
                #default="validationContext"
                name="Nova Especialidade"
                rules="required"
                immediate
              >
                <b-form-group
                  label="Nova Especialidade"
                  label-for="new-speciality"
                >
                  <b-form-input
                    id="new-speciality"
                    v-model="newSpeciality"
                    :state="getValidationState(validationContext) ? null : false"
                    @keydown.enter="!invalid ? createSpeciality(newSpeciality) : ''"
                  />
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="4"
              class="mt-2"
            >
              <b-button
                variant="primary"
                block
                :disabled="invalid"
                @click="createSpeciality(newSpeciality)"
              >
                Cadastrar
              </b-button>
            </b-col>

            <b-col
              cols="12"
              md="12"
            >
              <!-- Table Container Card -->
              <b-card
                no-body
                class="mb-0"
              >

                <div class="m-2">

                  <!-- Table Top -->
                  <b-row>

                    <!-- Search -->
                    <b-col
                      cols="12"
                      md="12"
                    >
                      <div class="d-flex align-items-center justify-content-end">
                        <b-form-input
                          v-model="searchQuery"
                          class="d-inline-block"
                          placeholder="Buscar..."
                          debounce="500"
                        />

                      </div>
                    </b-col>

                  </b-row>

                </div>

                <b-table
                  ref="refTable"
                  class="position-relative mb-0 tableMinHeght"
                  small
                  responsive
                  :fields="tableColumns"
                  :items="specialities"
                  primary-key="id"
                  sort-by="speciality"
                  show-empty
                  :filter="searchQuery"
                  empty-text="Nenhum resultado encontrado"
                  empty-filtered-text="Nenhum resultado encontrado para esta pesquisa"
                  :sort-desc="false"
                  no-sort-reset
                >

                  <template #cell(speciality)="data">
                    <span v-if="!data.item.editMode">{{ data.item.speciality }}</span>

                    <b-row v-else>
                      <b-col
                        cols="12"
                        md="6"
                      >
                        <b-form-input
                          id="edit-speciality"
                          v-model="data.item.temp"
                          @keydown.enter="confirmEditSpeciality(data.item)"
                        />
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                      />
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <b-button
                          variant="outline-primary"
                          block
                          @click="confirmEditSpeciality(data.item)"
                        >
                          <feather-icon
                            icon="CheckCircleIcon"
                            size="16"
                            class="align-middle"
                            style="text-color: #9900CC"
                          />
                        </b-button>
                      </b-col>
                      <b-col
                        cols="12"
                        md="2"
                      >
                        <b-button
                          variant="outline-danger"
                          block
                          @click="delete(data.item.editMode); refetchData()"
                        >
                          <feather-icon
                            icon="XCircleIcon"
                            size="16"
                            class="align-middle text-danger"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                  </template>

                  <template #cell(actions)="data">
                    <b-dropdown
                      variant="link"
                      no-caret
                      :right="$store.state.appConfig.isRTL"
                    >

                      <template #button-content>
                        <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="align-middle text-body"
                        />
                      </template>

                      <b-dropdown-item
                        @click="editSpeciality(data)"
                      >
                        <feather-icon icon="EditIcon" />
                        <span class="align-middle ml-50">Editar</span>
                      </b-dropdown-item>

                      <b-dropdown-item
                        @click="deleteSpeciality(data)"
                      >
                        <feather-icon icon="TrashIcon" />
                        <span class="align-middle ml-50">Excluir</span>
                      </b-dropdown-item>

                    </b-dropdown>
                  </template>

                </b-table>

                <div class="mx-2 mb-1">
                  <hr>
                  <b-row>
                    <b-col
                      cols="12"
                      sm="12"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                      <span class="text-muted">Total: {{ specialities.length }} registros.</span>
                    </b-col>
                  </b-row>
                </div>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BTable, BFormInvalidFeedback,
  BCard, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { toRefs, ref, watch } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import useManageSpecialitiesModal from './useManageSpecialitiesModal'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BTable,
    BCard,
    BDropdown,
    BDropdownItem,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    specialities: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit, root }) {
    const {
      tableColumns,
      perPage,
      totalData,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refetchData,
      removeSpeciality,
      newSpeciality,
      createSpeciality,
      editSpeciality,
      confirmEditSpeciality,
      refTable,
    } = useManageSpecialitiesModal()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const showLocal = ref(false)

    const { show } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })

    const { specialities } = toRefs(props)

    const resetEditMode = el => {
      if (el !== undefined) {
        // eslint-disable-next-line no-param-reassign
        el.editMode = false
      }
    }

    const hidden = () => {
      specialities.value.map(el => (el.editMode === true ? resetEditMode(el) : el))
      emit('hidden')
    }

    const deleteSpeciality = data => {
      root.$bvModal
        .msgBoxConfirm('Deseja Excluir esta especialidade?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) removeSpeciality(data.item)
        })
    }

    return {
      hidden,
      showLocal,

      tableColumns,
      perPage,
      totalData,
      currentPage,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refTable,
      refetchData,
      removeSpeciality,
      newSpeciality,
      createSpeciality,
      deleteSpeciality,
      editSpeciality,
      confirmEditSpeciality,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
