<template>
  <div>
    <validation-observer
      #default="{ invalid }"
      ref="refFormObserver"
    >
      <b-modal
        id="modal-delete-schedules"
        v-model="showLocal"
        centered
        size="md"
        title="Excluir Horários"
        hide-footer
        @hidden="hidden"
      >
        <b-form>
          <b-row>

            <b-col
              cols="12"
              md="12"
            >
              <b-alert
                variant="warning"
                show
              >
                <h4 class="alert-heading">
                  Atenção!
                </h4>
                <div class="alert-body">
                  <p><strong>TODOS</strong> os horários dentro deste intervalo serão excluídos <strong>PERMANENTEMENTE!</strong></p>
                </div>
              </b-alert>
            </b-col>

            <!-- Field: Data Inicial -->
            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="Data inicial"
                rules="required"
                immediate
              >
                <b-form-group
                  label="Data inicial"
                  label-for="delete-form-period-start"
                >
                  <b-form-input
                    id="delete-form-period-start"
                    v-model="deleteScheduleForm.period_start"
                    :state="getValidationState(validationContext) ? null : false"
                    type="date"
                    trim
                  />
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

            <!-- Field: Data Final -->
            <b-col
              cols="12"
              md="12"
            >
              <validation-provider
                #default="validationContext"
                name="Data final"
                rules="required"
                immediate
              >
                <b-form-group
                  label="Data final"
                  label-for="delete-form-period-end"
                >
                  <b-form-input
                    id="delete-form-period-end"
                    v-model="deleteScheduleForm.period_end"
                    :state="getValidationState(validationContext) ? null : false"
                    type="date"
                    trim
                  />
                  <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>

          </b-row>

          <hr>

          <!-- Footer -->
          <b-row>
            <b-col
              cols="12"
              md="4"
            />

            <b-col
              cols="12"
              md="4"
            >
              <b-button
                variant="outline-secondary"
                block
                @click="hidden"
              >
                Cancelar
              </b-button>
            </b-col>

            <b-col
              cols="12"
              md="4"
            >
              <b-button
                variant="primary"
                block
                :disabled="invalid"
                @click="deleteSchedule"
              >
                Confirmar
              </b-button>
            </b-col>
          </b-row>

        </b-form>
      </b-modal>
    </validation-observer>
  </div>
</template>

<script>
import {
  BForm, BRow, BCol, BButton, BFormGroup, BFormInput, BAlert, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { toRefs, ref, watch } from '@vue/composition-api'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import useDeleteSchedulesModal from './useDeleteSchedulesModal'

export default {
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BAlert,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit, root }) {
    const {
      deleteScheduleForm,
      launchPayload,

      resetDeleteScheduleForm,
    } = useDeleteSchedulesModal()

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetDeleteScheduleForm)

    const showLocal = ref(false)

    const { show } = toRefs(props)
    watch(show, newValue => {
      showLocal.value = newValue
    })

    const deleteSchedule = () => {
      root.$bvModal
        .msgBoxConfirm('Atenção, esta ação não poderá ser desfeita, você tem certeza que deseja excluir a margem de horários especificada?', {
          title: 'Por Favor Confirme esta Ação',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Sim',
          cancelTitle: 'Não',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            launchPayload()
            resetDeleteScheduleForm()
            emit('hidden')
          }
        })
    }

    const hidden = () => {
      resetDeleteScheduleForm()
      emit('hidden')
    }

    return {
      deleteScheduleForm,
      launchPayload,

      deleteSchedule,
      hidden,
      showLocal,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}

</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
