import {
  ref,
  watch,
  computed,
  onUnmounted,
} from '@vue/composition-api'

import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'
import userStoreModule from '@/views/apps/user/userStoreModule'

export default function useDentist() {
  const USER_APP_STORE_MODULE_NAME = 'app-user'

  // Register module
  if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  })

  // Use toast
  const toast = useToast()

  const refTable = ref(null)
  const showOverlay = ref(false)

  // Table Handlers
  const tableColumns = [
    {
      key: 'id',
      label: 'COD',
      formatter: title,
      sortable: true,
    },
    {
      key: 'name',
      label: 'Nome',
      tdClass: 'nameTdClass',
      sortable: true,
    },
    {
      key: 'deleted_at',
      label: 'Status',
      formatter: val => `${val ? 'Inativo' : 'Ativo'}`,
      tdClass: 'statusTdClass',
      sortable: true,
    },
    {
      key: 'actions',
      label: 'ações',
      tdClass: 'actionsTdClass',
      sortable: false,
    },
  ]
  const perPage = ref(10)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50]
  const searchQuery = ref('')
  const sortBy = ref('name')
  const isSortDirDesc = ref(false)
  const roles = ref(['DENTIST', 'ADMIN'])

  const dataMeta = computed(() => {
    const localItemsCount = refTable.value ? refTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const refetchData = () => {
    refTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  const showToast = (variant = 'success', icon = 'CheckIcon', titleToast = 'Sucesso', text = 'Dados Salvos com Sucesso.') => {
    toast({
      component: ToastificationContent,
      props: {
        titleToast,
        icon,
        text,
        variant,
      },
    })
  }

  const catchErr = e => {
    if (e.response && (e.response.status === 412 || e.response.status === 422)) showToast('warning', 'AlertCircleIcon', 'Atenção', e.response.data.message)
    else if (e.response.status === 401) showToast('danger', 'AlertCircleIcon', 'Atenção', 'Sua sessão expirou, faça novo login!')
    else showToast('danger', 'AlertCircleIcon', 'Atenção', 'Desculpe... Algo não saiu como esperado.')
  }

  const fetch = (ctx, callback) => {
    showOverlay.value = true
    store
      .dispatch('app-user/fetch', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        roles: roles.value,
        sort: isSortDirDesc.value ? 'desc' : 'asc',
        withTrashed: true,
      })
      .then(resp => {
        showOverlay.value = false
        const { data, total } = resp.data

        callback(data)
        totalData.value = total
      })
      .catch(e => {
        showOverlay.value = false
        catchErr(e)
      })
  }

  const deleteData = async data => {
    try {
      await store.dispatch('app-user/inactivate', data)
      showToast('success', 'CheckIcon', 'Sucesso', 'Dados Alterados com Sucesso.')
      refetchData()
    } catch (e) {
      catchErr(e)
    }
  }

  const reactiveData = async data => {
    try {
      await store.dispatch('app-user/reactive', data)
      showToast('success', 'CheckIcon', 'Sucesso', 'Dados Alterados com Sucesso.')
      refetchData()
    } catch (e) {
      catchErr(e)
    }
  }

  const blankDentistData = {
    id: null,
    photo: '',
    phones: [
      { phone: null, type: 'MOBILE' },
      { phone: null, type: 'PHONE' },
      { phone: null, type: 'OTHER' },
    ],
    gender: 'UNDEFINED',
    role: 'DENTIST',
  }
  const dentist = ref(blankDentistData)

  return {
    tableColumns,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refTable,
    dentist,
    blankDentistData,
    showOverlay,

    fetch,
    refetchData,
    deleteData,
    reactiveData,
  }
}
